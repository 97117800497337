import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '../components/layout'
import Hero from '../components/Hero'
import SEO from '../components/seo'

const HireUsPage = ({ data, location }) => {

    return(
        <Layout
          hero={
            <Hero />
          }
        >
          <SEO
            title="Thank you!"
            path={location.pathname}
          />
            <section className="bg-gray">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h2 className="mb-5">Success!</h2>
                    <h3>Thank you for contacting us. Someone will be in touch shortly.</h3>
                  </Col>
                </Row>
              </Container>
            </section>
        </Layout>
    )
}

export default HireUsPage